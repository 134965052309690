import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

const DialogueBalloonCustomizer = ({ open, onClose, onSave }) => {
  const [dialogueText, setDialogueText] = useState('');
  const [balloonStyle, setBalloonStyle] = useState('');
  const [balloonPosition, setBalloonPosition] = useState('');
  const [balloonColor, setBalloonColor] = useState('');
  const [fontFamily, setFontFamily] = useState('');
  const [fontSize, setFontSize] = useState('');
  const [fontEmphasis, setFontEmphasis] = useState('');

  const handleSave = () => {
    let dialogueConfig = [];
    if (dialogueText) {
      dialogueConfig.push(`with the exact caption`);
      dialogueConfig.push(`"${dialogueText.toUpperCase()}"`);
    }
    if (balloonPosition) dialogueConfig.push(`${balloonPosition.toUpperCase()} CHARACTER`);
    if (balloonStyle) dialogueConfig.push(balloonStyle);
    if (balloonColor) dialogueConfig.push(`in ${balloonColor} color`);
    if (fontFamily) dialogueConfig.push(`with ${fontFamily} font`);
    if (fontSize) dialogueConfig.push(`${fontSize} size`);
    if (fontEmphasis) dialogueConfig.push(fontEmphasis);
    
    onSave(dialogueConfig.join(', '));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Customize Dialogue Balloon</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Dialogue Text"
              value={dialogueText}
              onChange={(e) => setDialogueText(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Balloon Style</InputLabel>
              <Select value={balloonStyle} onChange={(e) => setBalloonStyle(e.target.value)}>
                <MenuItem value="Speech">Speech Balloon</MenuItem>
                <MenuItem value="Thought">Thought Bubble</MenuItem>
                <MenuItem value="Shout">Shout Balloon</MenuItem>
                <MenuItem value="Whisper">Whisper Balloon</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Balloon Position</InputLabel>
              <Select value={balloonPosition} onChange={(e) => setBalloonPosition(e.target.value)}>
                <MenuItem value="above">Above Character</MenuItem>
                <MenuItem value="below">Below Character</MenuItem>
                <MenuItem value="left of">Left of Character</MenuItem>
                <MenuItem value="right of">Right of Character</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Balloon Color</InputLabel>
              <Select value={balloonColor} onChange={(e) => setBalloonColor(e.target.value)}>
                <MenuItem value="white">White</MenuItem>
                <MenuItem value="yellow">Yellow</MenuItem>
                <MenuItem value="blue">Blue</MenuItem>
                <MenuItem value="green">Green</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Font Family</InputLabel>
              <Select value={fontFamily} onChange={(e) => setFontFamily(e.target.value)}>
                <MenuItem value="Arial">Arial</MenuItem>
                <MenuItem value="Times New Roman">Times New Roman</MenuItem>
                <MenuItem value="Courier">Courier</MenuItem>
                <MenuItem value="Comic Sans">Comic Sans</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Font Size</InputLabel>
              <Select value={fontSize} onChange={(e) => setFontSize(e.target.value)}>
                <MenuItem value="small">Small</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="large">Large</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Font Emphasis</InputLabel>
              <Select value={fontEmphasis} onChange={(e) => setFontEmphasis(e.target.value)}>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="bold">Bold</MenuItem>
                <MenuItem value="italic">Italic</MenuItem>
                <MenuItem value="underlined">Underlined</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogueBalloonCustomizer;
