import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Autocomplete, Chip, Box, Grid, Typography } from '@mui/material';

const styles = [
  { category: 'Render Styles', items: [
    { name: '3D Model', description: 'professional 3d model, octane render, highly detailed, volumetric, dramatic lighting' },
    { name: 'Analog Film', description: 'analog film photo, faded film, desaturated, 35mm photo, grainy, vignette, vintage, Kodachrome, Lomography, stained, highly detailed, found footage' },
    { name: 'Anime', description: 'anime artwork, anime style, vibrant, studio anime, highly detailed' },
    { name: 'Cinematic', description: 'cinematic film still, shallow depth of field, vignette, highly detailed, high budget, bokeh, cinemascope, moody, epic, gorgeous, film grain, grainy' },
    { name: 'Comic', description: 'comic, graphic illustration, comic art, graphic novel art, vibrant, highly detailed' },
    { name: 'Play-Doh', description: 'play-doh style, sculpture, clay art, centered composition, Claymation' },
    { name: 'Concept Art', description: 'concept art, digital artwork, illustrative, painterly, matte painting, highly detailed' },
    { name: 'Breathtaking', description: 'breathtaking, award-winning, professional, highly detailed' },
    { name: 'Ethereal Fantasy', description: 'ethereal fantasy concept art, magnificent, celestial, ethereal, painterly, epic, majestic, magical, fantasy art, cover art, dreamy' },
    { name: 'Isometric', description: 'isometric style, vibrant, beautiful, crisp, detailed, ultra detailed, intricate' }
  ]},
  { category: 'Art Styles', items: [
    { name: 'Line Art', description: 'line art drawing, professional, sleek, modern, minimalist, graphic, line art, vector graphics' },
    { name: 'Low-Poly', description: 'low-poly style, low-poly game art, polygon mesh, jagged, blocky, wireframe edges, centered composition' },
    { name: 'Neonpunk', description: 'neonpunk style, cyberpunk, vaporwave, neon, vibes, vibrant, stunningly beautiful, crisp, detailed, sleek, ultramodern, magenta highlights, dark purple shadows, high contrast, cinematic, ultra detailed, intricate, professional' },
    { name: 'Origami', description: 'origami style, paper art, pleated paper, folded, origami art, pleats, cut and fold, centered composition' },
    { name: 'Cinematic Photo', description: 'cinematic photo, 35mm photograph, film, bokeh, professional, 4k, highly detailed' },
    { name: 'Pixel Art', description: 'pixel-art, low-res, blocky, pixel art style, 8-bit graphics' },
    { name: 'Texture', description: 'texture, top down close-up' },
    { name: 'Abstract', description: 'abstract style, non-representational, colors and shapes, expression of feelings, imaginative, highly detailed' },
    { name: 'Abstract Expressionist', description: 'abstract expressionist painting, energetic brushwork, bold colors, abstract forms, expressive, emotional' },
    { name: 'Art Deco', description: 'art deco style, geometric shapes, bold colors, luxurious, elegant, decorative, symmetrical, ornate, detailed' }
  ]},
  { category: 'Design Styles', items: [
    { name: 'Advertising Poster', description: 'advertising poster style, Professional, modern, product-focused, commercial, eye-catching, highly detailed' },
    { name: 'Automotive Ad', description: 'automotive advertisement style, sleek, dynamic, professional, commercial, vehicle-focused, high-resolution, highly detailed' },
    { name: 'Corporate Branding', description: 'corporate branding style, professional, clean, modern, sleek, minimalist, business-oriented, highly detailed' },
    { name: 'Fashion Editorial', description: 'fashion editorial style, high fashion, trendy, stylish, editorial, magazine style, professional, highly detailed' },
    { name: 'Food Photography', description: 'food photography style, appetizing, professional, culinary, high-resolution, commercial, highly detailed' },
    { name: 'Gourmet Food', description: 'gourmet food photo, soft natural lighting, macro details, vibrant colors, fresh ingredients, glistening textures, bokeh background, styled plating, wooden tabletop, garnished, tantalizing, editorial quality' },
    { name: 'Luxury Product', description: 'luxury product style, elegant, sophisticated, high-end, luxurious, professional, highly detailed' },
    { name: 'Real Estate', description: 'real estate photography style, professional, inviting, well-lit, high-resolution, property-focused, commercial, highly detailed' },
    { name: 'Retail Packaging', description: 'retail packaging style, vibrant, enticing, commercial, product-focused, eye-catching, professional, highly detailed' },
    { name: 'Art Nouveau', description: 'art nouveau style, elegant, decorative, curvilinear forms, nature-inspired, ornate, detailed' }
  ]},
  { category: 'Artistic Movements', items: [
    { name: 'Constructivist', description: 'constructivist style, geometric shapes, bold colors, dynamic composition, propaganda art style' },
    { name: 'Cubist', description: 'cubist artwork, geometric shapes, abstract, innovative, revolutionary' },
    { name: 'Expressionist', description: 'expressionist, raw, emotional, dynamic, distortion for emotional effect, vibrant, use of unusual colors, detailed' },
    { name: 'Graffiti', description: 'graffiti style, street art, vibrant, urban, detailed, tag, mural' },
    { name: 'Hyperrealistic', description: 'hyperrealistic art, extremely high-resolution details, photographic, realism pushed to extreme, fine texture, incredibly lifelike' },
    { name: 'Impressionist', description: 'impressionist painting, loose brushwork, vibrant color, light and shadow play, captures feeling over form' },
    { name: 'Pointillism', description: 'pointillism style, composed entirely of small, distinct dots of color, vibrant, highly detailed' },
    { name: 'Pop Art', description: 'pop Art style, bright colors, bold outlines, popular culture themes, ironic or kitsch' },
    { name: 'Psychedelic', description: 'psychedelic style, vibrant colors, swirling patterns, abstract forms, surreal, trippy' },
    { name: 'Renaissance', description: 'renaissance style, realistic, perspective, light and shadow, religious or mythological themes, highly detailed' }
  ]},
  { category: 'Themed Styles', items: [
    { name: 'Steampunk', description: 'steampunk style, antique, mechanical, brass and copper tones, gears, intricate, detailed' },
    { name: 'Surrealist', description: 'surrealist art, dreamlike, mysterious, provocative, symbolic, intricate, detailed' },
    { name: 'Typographic', description: 'typographic art, stylized, intricate, detailed, artistic, text-based' },
    { name: 'Watercolor', description: 'watercolor painting, vibrant, beautiful, painterly, detailed, textural, artistic' },
    { name: 'Biomechanical', description: 'biomechanical style, blend of organic and mechanical elements, futuristic, cybernetic, detailed, intricate' },
    { name: 'Cyberpunk Biomech', description: 'biomechanical cyberpunk, cybernetics, human-machine fusion, dystopian, organic meets artificial, dark, intricate, highly detailed' },
    { name: 'Cybernetic', description: 'cybernetic style, futuristic, technological, cybernetic enhancements, robotics, artificial intelligence themes' },
    { name: 'Cybernetic Robot', description: 'cybernetic robot, android, AI, machine, metal, wires, tech, futuristic, highly detailed' },
    { name: 'Cyberpunk City', description: 'cyberpunk cityscape, neon lights, dark alleys, skyscrapers, futuristic, vibrant colors, high contrast, highly detailed' },
    { name: 'Futuristic', description: 'futuristic style, sleek, modern, ultramodern, high tech, detailed' }
  ]},
  { category: 'Retro and Game Styles', items: [
    { name: 'Retro Cyberpunk', description: 'retro cyberpunk, 80\'s inspired, synthwave, neon, vibrant, detailed, retro futurism' },
    { name: 'Retro-Futuristic', description: 'retro-futuristic, vintage sci-fi, 50s and 60s style, atomic age, vibrant, highly detailed' },
    { name: 'Sci-Fi', description: 'sci-fi style, futuristic, technological, alien worlds, space themes, advanced civilizations' },
    { name: 'Vaporwave', description: 'vaporwave style, retro aesthetic, cyberpunk, vibrant, neon colors, vintage 80s and 90s style, highly detailed' },
    { name: 'Bubble Bobble', description: 'Bubble Bobble style, 8-bit, cute, pixelated, fantasy, vibrant, reminiscent of Bubble Bobble game' },
    { name: 'Cyberpunk Game', description: 'cyberpunk game style, neon, dystopian, futuristic, digital, vibrant, detailed, high contrast, reminiscent of cyberpunk genre video games' },
    { name: 'Fighting Game', description: 'fighting game style, dynamic, vibrant, action-packed, detailed character design, reminiscent of fighting video games' },
    { name: 'GTA-style', description: 'GTA-style artwork, satirical, exaggerated, pop art style, vibrant colors, iconic characters, action-packed' },
    { name: 'Super Mario', description: 'Super Mario style, vibrant, cute, cartoony, fantasy, playful, reminiscent of Super Mario series' },
    { name: 'Minecraft', description: 'Minecraft style, blocky, pixelated, vibrant colors, recognizable characters and objects, game assets' }
  ]},
  { category: 'Themed and Conceptual', items: [
    { name: 'Pokémon', description: 'Pokémon style, vibrant, cute, anime, fantasy, reminiscent of Pokémon series' },
    { name: 'Retro Arcade', description: 'retro arcade style, 8-bit, pixelated, vibrant, classic video game, old school gaming, reminiscent of 80s and 90s arcade games' },
    { name: 'Retro Game Art', description: 'retro game art, 16-bit, vibrant colors, pixelated, nostalgic, charming, fun' },
    { name: 'RPG Fantasy', description: 'role-playing game (RPG) style fantasy, detailed, vibrant, immersive, reminiscent of high fantasy RPG games' },
    { name: 'Strategy Game', description: 'strategy game style, overhead view, detailed map, units, reminiscent of real-time strategy video games' },
    { name: 'Street Fighter', description: 'Street Fighter style, vibrant, dynamic, arcade, 2D fighting game, highly detailed, reminiscent of Street Fighter series' },
    { name: 'Legend of Zelda', description: 'Legend of Zelda style, vibrant, fantasy, detailed, epic, heroic, reminiscent of The Legend of Zelda series' },
    { name: 'Architectural', description: 'architectural style, clean lines, geometric shapes, minimalist, modern, architectural drawing, highly detailed' },
    { name: 'Disco', description: 'disco-themed, vibrant, groovy, retro 70s style, shiny disco balls, neon lights, dance floor, highly detailed' },
    { name: 'Dreamscape', description: 'dreamscape, surreal, ethereal, dreamy, mysterious, fantasy, highly detailed' }
  ]},
  { category: 'Atmospheric and Cultural', items: [
    { name: 'Dystopian', description: 'dystopian style, bleak, post-apocalyptic, somber' },
    { name: 'Fairy Tale', description: 'fairy tale, magical, fantastical, enchanting, storybook style, highly detailed' },
    { name: 'Gothic', description: 'gothic style, dark, mysterious, haunting, dramatic, ornate, detailed' },
    { name: 'Grunge', description: 'grunge style, textured, distressed, vintage, edgy, punk rock vibe, dirty, noisy' },
    { name: 'Horror', description: 'horror-themed, eerie, unsettling, dark, spooky, suspenseful, grim, highly detailed' },
    { name: 'Kawaii', description: 'kawaii style, cute, adorable, brightly colored, cheerful, anime influence, highly detailed' },
    { name: 'Lovecraftian', description: 'lovecraftian horror, eldritch, cosmic horror, unknown, mysterious, surreal, highly detailed' },
    { name: 'Macabre', description: 'macabre style, dark, gothic, grim, haunting, highly detailed' },
    { name: 'Manga', description: 'manga style, vibrant, high-energy, detailed, iconic, Japanese comic style' },
    { name: 'Metropolis', description: 'metropolis-themed, urban, cityscape, skyscrapers, modern, futuristic, highly detailed' }
  ]},
  { category: 'Artistic Techniques', items: [
    { name: 'Minimalist', description: 'minimalist style, simple, clean, uncluttered, modern, elegant' },
    { name: 'Monochrome', description: 'monochrome, black and white, contrast, tone, texture, detailed' },
    { name: 'Nautical', description: 'nautical-themed, sea, ocean, ships, maritime, beach, marine life, highly detailed' },
    { name: 'Space', description: 'space-themed, cosmic, celestial, stars, galaxies, nebulas, planets, science fiction, highly detailed' },
    { name: 'Stained Glass', description: 'stained glass style, vibrant, beautiful, translucent, intricate, detailed' },
    { name: 'Techwear', description: 'techwear fashion, futuristic, cyberpunk, urban, tactical, sleek, dark, highly detailed' },
    { name: 'Tribal', description: 'tribal style, indigenous, ethnic, traditional patterns, bold, natural colors, highly detailed' },
    { name: 'Zentangle', description: 'zentangle, intricate, abstract, monochrome, patterns, meditative, highly detailed' },
    { name: 'Collage', description: 'collage style, mixed media, layered, textural, detailed, artistic' },
    { name: 'Flat Papercut', description: 'flat papercut style, silhouette, clean cuts, paper, sharp edges, minimalist, color block' }
  ]},
  { category: 'Paper Art and Themed', items: [
    { name: 'Kirigami', description: 'kirigami representation, 3D, paper folding, paper cutting, Japanese, intricate, symmetrical, precision, clean lines' },
    { name: 'Paper Mache', description: 'paper mache representation, 3D, sculptural, textured, handmade, vibrant, fun' },
    { name: 'Paper Quilling', description: 'paper quilling art, intricate, delicate, curling, rolling, shaping, coiling, loops, 3D, dimensional, ornamental' },
    { name: 'Papercut Collage', description: 'papercut collage, mixed media, textured paper, overlapping, asymmetrical, abstract, vibrant' },
    { name: '3D Papercut', description: '3D papercut shadow box, layered, dimensional, depth, silhouette, shadow, papercut, handmade, high contrast' },
    { name: 'Stacked Papercut', description: 'stacked papercut art, 3D, layered, dimensional, depth, precision cut, stacked layers, papercut, high contrast' },
    { name: 'Thick Layered Papercut', description: 'thick layered papercut art, deep 3D, volumetric, dimensional, depth, thick paper, high stack, heavy texture, tangible layers' },
    { name: 'Alien', description: 'alien-themed, extraterrestrial, cosmic, otherworldly, mysterious, sci-fi, highly detailed' },
    { name: 'Film Noir', description: 'film noir style, monochrome, high contrast, dramatic shadows, 1940s style, mysterious, cinematic' },
    { name: 'Glamorous', description: 'glamorous photo, high fashion, luxurious, extravagant, stylish, sensual, opulent, elegance, stunning beauty, professional, high contrast, detailed' }
  ]},
  { category: 'Renaissance Masters', items: [
    { name: 'Sandro Botticelli', description: 'Early Renaissance, mythological themes, graceful figures, flowing lines' },
    { name: 'Leonardo da Vinci', description: 'High Renaissance, sfumato technique, anatomical precision, enigmatic expressions' },
    { name: 'Michelangelo', description: 'High Renaissance, sculptural forms, powerful figures, Sistine Chapel frescoes' },
    { name: 'Raphael', description: 'High Renaissance, harmonious compositions, idealized beauty, Madonnas and frescoes' },
    { name: 'Titian', description: 'Venetian Renaissance, rich colors, sensuous textures, mythological and religious scenes' }
  ]}
];

const artists = [
  { name: 'Alvar Aalto', description: 'Organic modernism, fluid forms, nature-inspired, functionalism, innovative use of materials, Scandinavian design, human-centered approach' },
  { name: 'Slim Aarons', description: 'Glamorous lifestyle photography, jet set culture, leisure, elegance, vibrant colors, capturing the elite at play, candid moments, aspirational' },
  { name: 'Edwin Austin Abbey', description: 'Historical narrative paintings, theatrical compositions, Victorian era, detailed storytelling, romanticism, rich colors, intricate brushwork' },
  { name: 'Gertrude Abercrombie', description: 'Surrealism, dreamlike imagery, personal symbolism, enigmatic narratives, dark humor, jazz-inspired, introspective' },
  { name: 'Marina Abramović', description: 'Body art, endurance performances, exploring human emotions and relationships, ritualistic, confrontational, boundary-pushing, spiritual' },
  { name: 'Tomma Abts', description: 'Geometric abstraction, precise compositions, subtle color variations, meticulous technique, enigmatic forms, meditative' },
  { name: 'Vito Acconci', description: 'Conceptual art, body-oriented performances, interactive installations, exploring architecture and space, psychological investigations, provocative' },
  { name: 'Andreas Achenbach', description: 'Romantic landscape painting, dramatic seascapes, atmospheric effects, sublime nature, luminous skies, realism with emotional depth' },
  { name: 'Norman Ackroyd', description: 'Etching, aquatint, capturing the essence of coastal landscapes, seascapes, rugged terrain, atmospheric effects, delicate lines' },
  { name: 'Ansel Adams', description: 'Black and white landscape photography, environmentalism, majestic natural landscapes, precise compositions, deep contrasts, Ansel Adams Zone System' },
  { name: 'Arthur Adams', description: 'Dynamic comic book art, superhero illustrations, intricate linework, detailed backgrounds, action-packed scenes, iconic characters' },
  { name: 'Neal Adams', description: 'Realistic comic book art, dynamic anatomy, cinematic compositions, influential storytelling, redefining superhero aesthetics' },
  { name: 'Josh Adamski', description: 'Contemporary sculpture, abstract forms, kinetic elements, exploring movement and balance, industrial materials, minimalist aesthetics' },
  { name: 'Charles Addams', description: 'Dark humor cartoons, macabre illustrations, gothic aesthetics, twisted domestic scenes' },
  { name: 'Etel Adnan', description: 'Abstract landscapes, vibrant colors, minimalist poetry, exploring themes of war, identity, and memory' },
  { name: 'Mattias Adolfsson', description: 'Detailed pen and ink drawings, whimsical narratives, surreal worlds, intricate architecture, fantastical creatures, playful imagination' },
  { name: 'Alena Aenami', description: 'Surreal digital illustrations, dreamscapes, ethereal environments, cinematic lighting, storytelling through visuals, otherworldly atmospheres' },
  { name: 'Pieter Aertsen', description: 'Northern Renaissance painting, still lifes, biblical narratives, detailed realism, allegorical elements, everyday scenes with moral undertones' },
  { name: 'Hilma af Klint', description: 'Abstract symbolism, spiritualism, pioneering abstract art, geometric shapes, esoteric themes, connection to the spiritual world' },
  { name: 'Affandi', description: 'Expressionistic, bold, vibrant, emotive' },
  { name: 'Leonid Afremov', description: 'Palette knife oil paintings, vibrant colors, romantic cityscapes, impressionistic style, capturing light and atmosphere, nostalgic charm' },
  { name: 'Yaacov Agam', description: 'Optical art, kinetic sculptures, interactive artworks, exploring movement and perception, vibrant colors, geometric patterns, visual illusions' },
  { name: 'Eileen Agar', description: 'Surrealist art, dreamlike imagery, biomorphic forms, mixed media collages, exploring the subconscious, feminist themes' },
  { name: 'Jacques-Laurent Agasse', description: 'Animal painting, scientific accuracy, detailed realism, natural history illustrations, zoological subjects, capturing the beauty and behavior of animals' },
  { name: 'Craigie Aitchison', description: 'Figurative painting, simplified forms, bold color contrasts, spiritual themes, religious symbolism, Scottish landscapes, poetic compositions' },
  { name: 'Doug Aitken', description: 'Contemporary multimedia, immersive installations, experimental video art, exploring human perception and experience, urban landscapes, site-specific interventions' },
  { name: 'Ivan Aivazovsky', description: 'Romantic seascape painting, luminous skies, dramatic seascapes, atmospheric effects, capturing the power and beauty of the sea, historical and mythological themes' },
  { name: 'David Aja', description: 'Graphic comic book art, minimalist style, dynamic compositions, innovative storytelling techniques, strong graphic design elements, evocative use of negative space' },
  { name: 'Sherry Akrami', description: 'Contemporary Iranian art, figurative painting, poetic symbolism, dreamlike landscapes, vibrant colors, cultural identity exploration' },
  { name: 'Rashad Alakbarov', description: 'Conceptual lights, shadow installations, using light and objects to create shadow images, exploring cultural and social themes, illusionary effects' },
  { name: 'Anni Albers', description: 'Bauhaus textile, geometric patterns, abstract compositions, innovative weaving techniques, exploring texture and materiality, modernist design principles' },
  { name: 'Josef Albers', description: 'Geometric abstraction, color theory studies, series of squares, exploring the interaction of colors, optical illusions, Bauhaus teachings' },
  { name: 'Alessio Albi', description: 'Fine art portrait photography, ethereal atmospheres, natural light, emotive expressions, storytelling through imagery, cinematic aesthetics' },
  { name: 'Ivan Albright', description: 'Magic realism, detailed and grotesque portraits, symbolic imagery, exploring the fragility of life and the passage of time, haunting and eerie atmospheres' },
  { name: 'Rafael Albuquerque', description: 'Comic book art, dynamic storytelling, expressive characters, cinematic compositions, atmospheric coloring, horror and supernatural themes' },
  { name: 'Miles Aldridge', description: 'Fashion photography, cinematic portraits, vibrant colors, surreal and theatrical compositions, exploring themes of beauty, desire, and fantasy' },
  { name: 'Alex Alemany', description: 'Surreal urban landscapes, magical realism, atmospheric lighting, architectural elements, dreamlike compositions, mysterious narratives' },
  { name: 'John White Alexander', description: 'American impressionism, elegant portraits, allegorical murals, decorative compositions, capturing the beauty of the human form, romantic and idealized scenes' },
  { name: 'Helen Allingham', description: 'Victorian watercolor, rural landscapes, quaint English villages, atmospheric effects, nostalgic scenes, English countryside, late 19th century' },
  { name: 'Alessandro Allori', description: 'Mannerism, religious and mythological subjects, elegant compositions, rich colors, meticulous details, refined style' },
  { name: 'Mike Allred', description: 'Pop art, comic book, retro-inspired style, bold linework, vibrant colors, surreal and psychedelic imagery, quirky characters, blending humor and action' },
  { name: 'Nick Alm', description: 'Contemporary figurative painting, hyperrealistic portraits, psychological depth, exploring human emotions and relationships, cinematic lighting, classical influences' },
  { name: 'Lawrence Alma-Tadema', description: 'Victorian, neoclassical, ancient Roman and Greek subjects, luxurious settings, meticulous attention to detail, idealized beauty, historical accuracy' },
  { name: 'Lilia Alvarado', description: 'Surreal figurative art, dreamlike narratives, symbolic imagery, vibrant colors, exploring personal and cultural identity, Latin American influences' },
  { name: 'Emil Alzamora', description: 'Figurative sculpture, exploring the human form and emotion, elongated and distorted figures, surreal and introspective themes, fluid and expressive forms' },
  { name: 'Yoshitaka Amano', description: 'manga, character designer, ethereal and detailed illustrations, fantasy and science fiction themes, intricate linework, elegant and otherworldly designs' },
  { name: 'Ghada Amer', description: 'Contemporary, embroidered paintings, exploring themes of gender sexuality and identity, blending Eastern and Western influences, provocative and subversive' },
  { name: 'Cuno Amiet', description: 'expressionism, vibrant colors, bold brushwork, landscapes and portraits, influenced by Fauvism and German Expressionism, capturing the essence of Swiss nature' },
  { name: 'Rick Amor', description: 'Contemporary, figurative painting, urban landscapes, haunting and atmospheric scenes, exploring themes of memory, loss, and isolation' },
  { name: 'Fernando Amorsolo', description: 'romantic realism, rural landscapes, genre scenes, idealized portraits, capturing the beauty and simplicity of Filipino life, soft and luminous lighting' },
  { name: 'Jay Anacleto', description: 'Comics, realistic style, dynamic compositions, detailed linework, cinematic storytelling, iconic superhero illustrations, blending classical and contemporary influences' },
  { name: 'El Anatsui', description: 'found object art, large-scale installations, metal wall sculptures, exploring themes of colonialism, globalization, and African identity, intricate and shimmering compositions' },
  { name: 'Anna Ancher', description: 'impressionism, intimate interior scenes, domestic life, capturing the play of light and color, soft and atmospheric compositions' },
  { name: 'Michael Ancher', description: 'narrative genre scenes, coastal landscapes, social realism, capturing the hardships and beauty of Danish fishermen life, dramatic lighting' },
  { name: 'Hans Christian Andersen', description: 'fairy tales and children\'s stories, timeless classics, magical and moralistic narratives' },
  { name: 'Sarah Andersen', description: 'Cartoonist, relatable webcomics, humorous and introspective observations on everyday life, quirky characters, simple and expressive artwork' },
  { name: 'Richard Anderson', description: 'Concept art, cinematic illustrations, science fiction and fantasy themes, atmospheric environments, dramatic lighting, creating immersive worlds for film and games' },
  { name: 'Sophie Anderson', description: 'Victorian, neoclassical, sentimental genre scenes, romanticized depictions of children and women, fairy tale motifs, soft and delicate brushwork, idealized beauty' },
  { name: 'Wes Anderson', description: 'distinctive visual style, quirky characters, symmetrical compositions, pastel color palettes, deadpan humor, whimsical and nostalgic storytelling' },
  { name: 'Tadao Ando', description: 'minimalist design, use of concrete and natural light, creating serene and contemplative spaces, blending modernist principles with traditional Japanese aesthetics' },
  { name: 'Alex Andreev', description: 'Surreal urban landscapes, dystopian atmospheres, mysterious and surreal narratives, atmospheric lighting, blending realism with fantasy elements' },
  { name: 'Esao Andrews', description: 'Surreal figurative painting, dreamlike imagery, symbolic narratives, haunting and atmospheric scenes, detailed and intricate compositions, influenced by comics and street art' },
  { name: 'Fra Angelico', description: 'Early Italian Renaissance, religious subjects, devotional art, luminous colors, gentle and graceful figures, spiritual and mystical atmosphere' },
  { name: 'Charles Angrand', description: 'pointillist technique, divisionism, capturing light and color in landscapes and urban scenes, meticulous brushwork, influenced by Seurat and Signac' },
  { name: 'Sofonisba Anguissola', description: 'portraits, historical and genre scenes, refined and sensitive' },
  { name: 'Miss Aniela', description: 'Fine art, surreal and conceptual self-portraits, blending photography with digital manipulation, exploring themes of identity, transformation, and fantasy' },
  { name: 'Albrecht Anker', description: 'realism, genre scenes, portraits, capturing the rural life of Switzerland, social realism, attention to detail, warm and sympathetic portrayals' },
  { name: 'Mary Anning', description: 'paleontology,prehistoric life, fossils' },
  { name: 'Louis Anquetin', description: 'post-impressionism, cloisonnism style, bold outlines, flat colors, urban nightlife scenes' },
  { name: 'Mary Jane Ansell', description: 'Neo-Victorian portraiture, classical realism, atmospheric lighting, intricate details, historical and literary influences, feminine mystique' },
  { name: 'Martin Ansin', description: 'Retro-futuristic illustration, intricate linework, cinematic compositions, vibrant colors, iconic movie posters, blending pop culture and fine art' },
  { name: 'Janine Antoni', description: 'Conceptual art, exploring themes of femininity, identity, and cultural norms, innovative use of materials, challenging traditional notions of art' },
  { name: 'Chiho Aoshima', description: 'Superflat art movement, kawaii aesthetics, fantastical and surreal imagery, blending traditional Japanese motifs with contemporary themes, vibrant colors, otherworldly landscapes' },
  { name: 'Sabbas Apterus', description: 'Dark fantasy illustration, gothic and macabre themes, detailed and atmospheric compositions, intricate linework, exploring the depths of the imagination' },
  { name: 'Ron Arad', description: 'Industrial, sculptural furniture, fluid and organic forms, innovative use of materials such as steel and aluminum, blurring the boundaries between art and design' },
  { name: 'Sergio Aragonés', description: 'Mad Magazine, cartoon, humorous and satirical comics, intricate and detailed cartooning, visual storytelling through exaggerated characters and situations' },
  { name: 'Hirohiko Araki', description: 'Manga, dynamic and flamboyant character designs, surreal and imaginative storytelling, iconic poses and fashion, "JoJo\'s Bizarre Adventure,"' },
  { name: 'Nobuyoshi Araki', description: 'Controversial, intimate and provocative portraits, blending documentary and art photography' },
  { name: 'Shinji Aramaki', description: 'Anime, mecha and cyberpunk aesthetics, futuristic worlds, intricate mechanical designs, dynamic action sequences, blending traditional and CGI animation techniques' },
  { name: 'Diane Arbus', description: 'Documentary photography, capturing marginalized and unconventional subjects, exploring themes of identity, eccentricity, and the human condition, candid and empathetic portraits' },
  { name: 'Cory Arcangel', description: 'Contemporary, hacking and manipulating digital technologies, deconstructing and remixing pop culture and internet imagery, exploring the absurdity of contemporary society' },
  { name: 'Archillect', description: 'algorithmically generated imagery, exploring the intersection of technology, art, and culture, creating a visual dialogue through curated content' },
  { name: 'Alexander Archipenko', description: 'Cubism, abstract forms, dynamic compositions, pioneering sculptural techniques, exploring the relationship between space and form, influenced by Futurism and Constructivism' },
  { name: 'Giuseppe Arcimboldo', description: 'Renaissance, fruit and vegetables, surreal and playful compositions, blending naturalism with allegory' },
  { name: 'Mordecai Ardon', description: 'abstract expressionism, exploring themes of Jewish identity, memory, and spirituality, vibrant colors, gestural brushwork, mystical and poetic compositions' },
  { name: 'Steve Argyle', description: 'Fantasy, illustration, Magic' },
  { name: 'Mark Arian', description: 'Photorealism, urban landscapes, nostalgic scenes, capturing the essence of American life, meticulous attention to detail, dramatic lighting' },
  { name: 'Abram Efimovich Arkhipov', description: 'critical realism, rural landscapes, genre scenes, capturing the everyday life of Russian peasants, empathetic portrayals, social commentary' },
  { name: 'Howard Arkley', description: 'pop art aesthetics, suburban landscapes, vibrant colors, exploring the kitsch and mundane aspects of suburban life, influenced by graffiti and street culture' },
  { name: 'Rolf Armstrong', description: 'Pin-up art, glamour illustrations, capturing the beauty and elegance of the Jazz Age, idealized and sensuous portrayals of women, lush and vibrant colors' },
  { name: 'Alois Arnegger', description: 'alpine landscapes, romantic realism, atmospheric effects, capturing the beauty and grandeur of the Austrian Alps, precise and detailed brushwork' },
  { name: 'Eve Arnold', description: 'photojournalism, documenting social issues, portraits of celebrities and political figures, humanist approach, capturing intimate and candid moments' },
  { name: 'Gerd Arntz', description: 'graphic design, social activist, pioneer of pictogram design, creating visual symbols to represent complex information, influencing modern signage and visual communication' },
  { name: 'Floris Arntzenius', description: 'Dutch impressionism, cityscapes and interiors, capturing the atmosphere of Dutch urban life, soft and muted colors, impressionistic brushwork' },
  { name: 'Guy Aroch', description: 'Fashion and portraits, cinematic and atmospheric imagery, nostalgic and dreamlike aesthetics, blending fashion with storytelling, creating evocative narratives' },
  { name: 'Ben Aronson', description: 'Urban landscapes, contemporary realism, cityscapes and street scenes, capturing the energy and atmosphere of urban environments, bold colors and dynamic compositions' },
  { name: 'Jean Arp', description: 'Dadaism, abstract sculpture, biomorphic forms, playful and organic compositions, exploring the spontaneity of artistic creation, influenced by Surrealism and Constructivism' },
  { name: 'Agostino Arrivabene', description: 'symbolism, mystical and esoteric imagery, metaphysical landscapes, exploring the realms of dreams and imagination, rich symbolism and allegory' }
];

const ArtistStyleCustomizer = ({ open, onClose, onSave }) => {
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);

  const handleSave = () => {
    const combined = [
      ...selectedStyles.map(style => style.description),
      ...selectedArtists.map(artist => `By ${artist.name}, ${artist.description}`)
    ];
    onSave(combined.join(', '));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Customize Artist & Style</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="style-tags"
              options={styles.flatMap(category => category.items)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Styles"
                  placeholder="Search and select styles"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...otherProps } = getTagProps({ index });
                  return (
                    <Chip key={key} variant="outlined" label={option.name} {...otherProps} />
                  );
                })
              }
              value={selectedStyles}
              onChange={(event, newValue) => {
                setSelectedStyles(newValue);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="artist-tags"
              options={artists}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Artists"
                  placeholder="Search and select artists"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...otherProps } = getTagProps({ index });
                  return (
                    <Chip key={key} variant="outlined" label={option.name} {...otherProps} />
                  );
                })
              }
              value={selectedArtists}
              onChange={(event, newValue) => {
                setSelectedArtists(newValue);
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Selected Artists & Styles"
            value={[...selectedStyles.map(style => style.description), ...selectedArtists.map(artist => artist.description)].join(', ')}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArtistStyleCustomizer;
