import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CharacterCustomizer = ({ open, onClose, onSave }) => {
  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [race, setRace] = useState('');
  const [hairColor, setHairColor] = useState('');
  const [faceExpression, setFaceExpression] = useState('');
  const [hairstyle, setHairstyle] = useState('');
  const [outfit, setOutfit] = useState('');

  const handleSave = () => {
    let characterDescription = [];
    if (gender) characterDescription.push(gender);
    if (ethnicity) characterDescription.push(ethnicity);
    if (race) characterDescription.push(race);
    if (hairColor || hairstyle) {
      let hairDesc = [];
      if (hairColor) hairDesc.push(hairColor);
      if (hairstyle) hairDesc.push(hairstyle);
      characterDescription.push(`with ${hairDesc.join(' ')} hair`);
    }
    if (faceExpression) characterDescription.push(`with ${faceExpression} expression`);
    if (outfit) characterDescription.push(`wearing ${outfit}`);
    onSave(characterDescription.join(', ').trim());
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Customize Character</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Gender</InputLabel>
              <Select value={gender} onChange={(e) => setGender(e.target.value)}>
                <MenuItem value="Woman">Woman</MenuItem>
                <MenuItem value="Man">Man</MenuItem>
                <MenuItem value="Non-binary">Non-binary</MenuItem>
                <MenuItem value="Genderfluid">Genderfluid</MenuItem>
                <MenuItem value="Shapeshifter">Shapeshifter</MenuItem>
                <MenuItem value="Sentient AI">Sentient AI</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Ethnicity</InputLabel>
              <Select value={ethnicity} onChange={(e) => setEthnicity(e.target.value)}>
                <MenuItem value="White">White</MenuItem>
                <MenuItem value="Black">Black</MenuItem>
                <MenuItem value="Asian">Asian</MenuItem>
                <MenuItem value="Latino">Latino</MenuItem>
                <MenuItem value="Middle Eastern">Middle Eastern</MenuItem>
                <MenuItem value="Mixed">Mixed</MenuItem>
                <MenuItem value="Indigenous">Indigenous</MenuItem>
                <MenuItem value="Extraterrestrial">Extraterrestrial</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Race</InputLabel>
              <Select value={race} onChange={(e) => setRace(e.target.value)}>
                <MenuItem value="Human">Human</MenuItem>
                <MenuItem value="Elf">Elf</MenuItem>
                <MenuItem value="Dwarf">Dwarf</MenuItem>
                <MenuItem value="Orc">Orc</MenuItem>
                <MenuItem value="Mermaid">Mermaid</MenuItem>
                <MenuItem value="Centaur">Centaur</MenuItem>
                <MenuItem value="Robot">Robot</MenuItem>
                <MenuItem value="Talking animal">Talking animal</MenuItem>
                <MenuItem value="Living plant">Living plant</MenuItem>
                <MenuItem value="Cosmic entity">Cosmic entity</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Hair Color</InputLabel>
              <Select value={hairColor} onChange={(e) => setHairColor(e.target.value)}>
                <MenuItem value="Black">Black</MenuItem>
                <MenuItem value="Blonde">Blonde</MenuItem>
                <MenuItem value="Brown">Brown</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="White">White</MenuItem>
                <MenuItem value="Rainbow">Rainbow</MenuItem>
                <MenuItem value="Neon green">Neon green</MenuItem>
                <MenuItem value="Metallic silver">Metallic silver</MenuItem>
                <MenuItem value="Galaxy print">Galaxy print</MenuItem>
                <MenuItem value="Mood-changing">Mood-changing</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Face Expression</InputLabel>
              <Select value={faceExpression} onChange={(e) => setFaceExpression(e.target.value)}>
                <MenuItem value="Happy">Happy</MenuItem>
                <MenuItem value="Sad">Sad</MenuItem>
                <MenuItem value="Angry">Angry</MenuItem>
                <MenuItem value="Surprised">Surprised</MenuItem>
                <MenuItem value="Mischievous">Mischievous</MenuItem>
                <MenuItem value="Confused">Confused</MenuItem>
                <MenuItem value="Determined">Determined</MenuItem>
                <MenuItem value="Sleepy">Sleepy</MenuItem>
                <MenuItem value="Starstruck">Starstruck</MenuItem>
                <MenuItem value="Dad joke face">Dad joke face</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Hairstyle</InputLabel>
              <Select value={hairstyle} onChange={(e) => setHairstyle(e.target.value)}>
                <MenuItem value="Long">Long</MenuItem>
                <MenuItem value="Short">Short</MenuItem>
                <MenuItem value="Curly">Curly</MenuItem>
                <MenuItem value="Straight">Straight</MenuItem>
                <MenuItem value="Mohawk">Mohawk</MenuItem>
                <MenuItem value="Afro">Afro</MenuItem>
                <MenuItem value="Dreadlocks">Dreadlocks</MenuItem>
                <MenuItem value="Pixie cut">Pixie cut</MenuItem>
                <MenuItem value="Bald with a single strand">Bald with a single strand</MenuItem>
                <MenuItem value="Medusa-style snakes">Medusa-style snakes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Outfit</InputLabel>
              <Select value={outfit} onChange={(e) => setOutfit(e.target.value)}>
                <MenuItem value="Casual">Casual</MenuItem>
                <MenuItem value="Formal">Formal</MenuItem>
                <MenuItem value="Fantasy">Fantasy</MenuItem>
                <MenuItem value="Sci-fi">Sci-fi</MenuItem>
                <MenuItem value="Superhero costume">Superhero costume</MenuItem>
                <MenuItem value="Steampunk attire">Steampunk attire</MenuItem>
                <MenuItem value="Underwater diving suit">Underwater diving suit</MenuItem>
                <MenuItem value="Made entirely of food">Made entirely of food</MenuItem>
                <MenuItem value="Living shadow">Living shadow</MenuItem>
                <MenuItem value="Interdimensional traveler gear">Interdimensional traveler gear</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CharacterCustomizer;
