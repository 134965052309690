import React, { useState } from 'react';
import { Box, IconButton, Popover, Button, useMediaQuery, useTheme, Typography } from '@mui/material';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

const DimensionSelector = ({ width, height, upscaleBy, onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const iconColor = theme.palette.primary.main;

  const dimensions = [
    { width: 1536, height: 640 },
    { width: 1344, height: 768 },
    { width: 1216, height: 832 },
    { width: 1152, height: 896 },
    { width: 1024, height: 1024 },
    { width: 896, height: 1152 },
    { width: 832, height: 1216 },
    { width: 768, height: 1344 },
    { width: 640, height: 1536 },
  ];

  const upscaleOptions = [1, 2, 3, 4];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDimensionChange = (dimension) => {
    onChange({ width: dimension.width, height: dimension.height, upscaleBy });
    handleClose();
  };

  const handleUpscaleChange = (value) => {
    onChange({ width, height, upscaleBy: value });
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: 'inline-block' }}>
      <IconButton onClick={handleClick} size="small">
        <AspectRatioIcon sx={{ color: iconColor }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>Dimensions</Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1, mb: 2 }}>
            {dimensions.map((dimension) => (
              <Button
                key={`${dimension.width}x${dimension.height}`}
                onClick={() => handleDimensionChange(dimension)}
                variant={width === dimension.width && height === dimension.height ? 'contained' : 'outlined'}
                size="small"
              >
                {isMobile ? `${dimension.width}` : `${dimension.width}x${dimension.height}`}
              </Button>
            ))}
          </Box>
          <Typography variant="subtitle2" gutterBottom>Upscale</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {upscaleOptions.map((value) => (
              <Button
                key={`upscale-${value}`}
                onClick={() => handleUpscaleChange(value)}
                variant={upscaleBy === value ? 'contained' : 'outlined'}
                size="small"
              >
                {`${value}x`}
              </Button>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DimensionSelector;
