import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const BackgroundCustomizer = ({ open, onClose, onSave }) => {
  const [setting, setSetting] = useState('');
  const [timeOfDay, setTimeOfDay] = useState('');
  const [weather, setWeather] = useState('');
  const [mood, setMood] = useState('');

  const handleSave = () => {
    let backgroundDescription = [];
    if (setting) backgroundDescription.push(setting);
    if (timeOfDay) backgroundDescription.push(`during ${timeOfDay}`);
    if (weather) backgroundDescription.push(`with ${weather} weather`);
    if (mood) backgroundDescription.push(`creating a ${mood} mood`);
    onSave(backgroundDescription.join(', ').trim());
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Customize Background</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Setting</InputLabel>
              <Select value={setting} onChange={(e) => setSetting(e.target.value)}>
                <MenuItem value="Urban cityscape">Urban cityscape</MenuItem>
                <MenuItem value="Lush forest">Lush forest</MenuItem>
                <MenuItem value="Sandy beach">Sandy beach</MenuItem>
                <MenuItem value="Snowy mountains">Snowy mountains</MenuItem>
                <MenuItem value="Desert landscape">Desert landscape</MenuItem>
                <MenuItem value="Underwater coral reef">Underwater coral reef</MenuItem>
                <MenuItem value="Alien planet surface">Alien planet surface</MenuItem>
                <MenuItem value="Inside a giant sandwich">Inside a giant sandwich</MenuItem>
                <MenuItem value="Steampunk airship dock">Steampunk airship dock</MenuItem>
                <MenuItem value="Floating sky islands">Floating sky islands</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Time of Day</InputLabel>
              <Select value={timeOfDay} onChange={(e) => setTimeOfDay(e.target.value)}>
                <MenuItem value="sunrise">Sunrise</MenuItem>
                <MenuItem value="midday">Midday</MenuItem>
                <MenuItem value="sunset">Sunset</MenuItem>
                <MenuItem value="night">Night</MenuItem>
                <MenuItem value="during a solar eclipse">During a solar eclipse</MenuItem>
                <MenuItem value="in a time loop">In a time loop</MenuItem>
                <MenuItem value="when pigs fly">When pigs fly</MenuItem>
                <MenuItem value="during the apocalypse">During the apocalypse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Weather</InputLabel>
              <Select value={weather} onChange={(e) => setWeather(e.target.value)}>
                <MenuItem value="clear">Clear</MenuItem>
                <MenuItem value="cloudy">Cloudy</MenuItem>
                <MenuItem value="rainy">Rainy</MenuItem>
                <MenuItem value="snowy">Snowy</MenuItem>
                <MenuItem value="stormy">Stormy</MenuItem>
                <MenuItem value="raining cats and dogs">Raining cats and dogs</MenuItem>
                <MenuItem value="meteor shower">Meteor shower</MenuItem>
                <MenuItem value="aurora borealis">Aurora borealis</MenuItem>
                <MenuItem value="sandstorm">Sandstorm</MenuItem>
                <MenuItem value="rainbow fog">Rainbow fog</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Mood</InputLabel>
              <Select value={mood} onChange={(e) => setMood(e.target.value)}>
                <MenuItem value="peaceful">Peaceful</MenuItem>
                <MenuItem value="mysterious">Mysterious</MenuItem>
                <MenuItem value="energetic">Energetic</MenuItem>
                <MenuItem value="romantic">Romantic</MenuItem>
                <MenuItem value="eerie">Eerie</MenuItem>
                <MenuItem value="whimsical">Whimsical</MenuItem>
                <MenuItem value="chaotic">Chaotic</MenuItem>
                <MenuItem value="nostalgic">Nostalgic</MenuItem>
                <MenuItem value="surreal">Surreal</MenuItem>
                <MenuItem value="groovy">Groovy</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BackgroundCustomizer;
