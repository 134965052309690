import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const OutfitBuilder = ({ open, onClose, onSave }) => {
  const [style, setStyle] = useState('');
  const [color, setColor] = useState('');
  const [material, setMaterial] = useState('');
  const [accessories, setAccessories] = useState('');

  const handleSave = () => {
    let outfitDescription = [];
    if (style) outfitDescription.push(style);
    if (color) outfitDescription.push(color);
    if (outfitDescription.length > 0) outfitDescription.push('outfit');
    if (material) outfitDescription.push(`made of ${material}`);
    if (accessories) outfitDescription.push(`with ${accessories}`);
    onSave(outfitDescription.join(' ').trim());
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Build Outfit</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Style</InputLabel>
              <Select value={style} onChange={(e) => setStyle(e.target.value)}>
                <MenuItem value="Casual">Casual</MenuItem>
                <MenuItem value="Formal">Formal</MenuItem>
                <MenuItem value="Sporty">Sporty</MenuItem>
                <MenuItem value="Bohemian">Bohemian</MenuItem>
                <MenuItem value="Vintage">Vintage</MenuItem>
                <MenuItem value="Cyberpunk">Cyberpunk</MenuItem>
                <MenuItem value="Post-apocalyptic">Post-apocalyptic</MenuItem>
                <MenuItem value="Medieval fantasy">Medieval fantasy</MenuItem>
                <MenuItem value="Retro-futuristic">Retro-futuristic</MenuItem>
                <MenuItem value="Avant-garde">Avant-garde</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Color</InputLabel>
              <Select value={color} onChange={(e) => setColor(e.target.value)}>
                <MenuItem value="Red">Red</MenuItem>
                <MenuItem value="Blue">Blue</MenuItem>
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Black">Black</MenuItem>
                <MenuItem value="White">White</MenuItem>
                <MenuItem value="Holographic">Holographic</MenuItem>
                <MenuItem value="Chameleon (color-changing)">Chameleon (color-changing)</MenuItem>
                <MenuItem value="Invisible">Invisible</MenuItem>
                <MenuItem value="Neon rainbow">Neon rainbow</MenuItem>
                <MenuItem value="Glitch pattern">Glitch pattern</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Material</InputLabel>
              <Select value={material} onChange={(e) => setMaterial(e.target.value)}>
                <MenuItem value="Cotton">Cotton</MenuItem>
                <MenuItem value="Silk">Silk</MenuItem>
                <MenuItem value="Leather">Leather</MenuItem>
                <MenuItem value="Denim">Denim</MenuItem>
                <MenuItem value="Wool">Wool</MenuItem>
                <MenuItem value="Living plant fibers">Living plant fibers</MenuItem>
                <MenuItem value="Liquid metal">Liquid metal</MenuItem>
                <MenuItem value="Hard light construct">Hard light construct</MenuItem>
                <MenuItem value="Cloud essence">Cloud essence</MenuItem>
                <MenuItem value="Repurposed meme fabric">Repurposed meme fabric</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Accessories</InputLabel>
              <Select value={accessories} onChange={(e) => setAccessories(e.target.value)}>
                <MenuItem value="Necklace">Necklace</MenuItem>
                <MenuItem value="Earrings">Earrings</MenuItem>
                <MenuItem value="Belt">Belt</MenuItem>
                <MenuItem value="Scarf">Scarf</MenuItem>
                <MenuItem value="Hat">Hat</MenuItem>
                <MenuItem value="Jetpack">Jetpack</MenuItem>
                <MenuItem value="Holographic wings">Holographic wings</MenuItem>
                <MenuItem value="Quantum pocket watch">Quantum pocket watch</MenuItem>
                <MenuItem value="Gravity-defying hair clips">Gravity-defying hair clips</MenuItem>
                <MenuItem value="Mood ring that actually works">Mood ring that actually works</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OutfitBuilder;
